
.login {
  .form-signin {
    width: 350px;
    margin: 150px auto auto;
    max-width: 100%;

    .logo {
      max-width: 100%;
      width: 100px;
      height: auto; } } }



#root {
  height: 100%;
  .charts {
    height: 100%;
    display: flex;
    flex-direction: column; } }

footer {
  margin-top: auto; }


.charts {
  .back-button {
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    right: 0;
    padding: 0 10px;
    z-index: 1; } }
